<template>
  <div class="project-condition-evaluation d-flex flex-column">
    <Backup class="align-self-start"></Backup>
    <div class="select-living d-flex align-items-center section-container dark selector-container"
       style="margin-bottom:1rem;">
       <el-form :inline="true">
         <!-- <RoomSelector @selectItem="selectItemListener"></RoomSelector> -->
         <!-- <el-form-item  size="small">
           <el-button size="mini" @click="exportToConditon">设备工况</el-button>
         </el-form-item> -->
       </el-form>
      </div>
    <SectionFrame class="none-height flex-grow-1">
      <div v-if="details.length > 0" class="conState">
        <div
          v-for="(item, index) in details"
          :key="index"
          class="situation"
          ref="situat"
        >
          <div class="situation1 section-living dark"
          ref="situat1">
            <div class="qui">
              <el-progress
                type="circle"
                :width="90"
                :percentage="item.Score"
                class="dark"
                style="font-size: 2rem;"
              ></el-progress>
            </div>
            <div class="dectext">
              <div class="nameAsolution">
                <div
                  class="flex"
                  style="width: 45%; font-size: 1.2rem; font-weight: 550"
                >
                  {{ item.TName }}
                </div>
                <div
                  class="flex"
                  :style="
                    Boolean(item.SolutionText)
                      ? 'color:#25c1ff;font-size:0.9rem'
                      : 'display:none'
                  "
                >
                  检修建议：{{ item.SolutionText }}
                </div>
              </div>
              <div class="cont1">
                <div class="flex" style="width: 25%; font-size: 0.9rem">
                  额定容量:
                  <span class="info">{{ item.GaiKuang.ZongRongLiang }}</span
                  >kVA
                </div>
                <div class="flex" style="width: 25%; font-size: 0.9rem">
                  产品型号:
                  <span class="info">{{ item.GaiKuang.ChanPinXingHao }}</span>
                </div>
                <div class="flex" style="width: 25%; font-size: 0.9rem">
                  额定电压:<span class="info">{{
                    item.GaiKuang.EDingDianYa
                  }}</span>
                </div>
                <div class="flex" style="width: 25%; font-size: 0.9rem">
                  额定电流:<span class="info">{{
                    item.GaiKuang.EDingDianLiu
                  }}</span>
                </div>
              </div>
              <div class="cont2">
                <div class="flex" style="width: 50%; font-size: 0.9rem">
                  生产厂家:<span class="info">{{
                    item.GaiKuang.ShengChanChangJia
                  }}</span>
                </div>
                <div class="flex" style="width: 50%; font-size: 0.9rem">
                  出产日期:<span class="info">{{
                    item.GaiKuang.ChuChangRiQi
                  }}</span>
                </div>
              </div>
              <div class="cont3">
                <div class="flex" style="width: 25%; font-size: 0.9rem">
                  当前负载率:<span class="info">{{
                    item.GaiKuang.DangQianFuZaiLv
                  }}</span>
                </div>
                <div class="flex" style="width: 25%; font-size: 0.9rem">
                  今日告警:<span class="danger">{{
                    item.GaiKuang.JinRiGaoJing
                  }}</span>
                </div>
                <div class="flex" style="width: 25%; font-size: 0.9rem">
                  已派单:<span class="success">{{
                    item.GaiKuang.YiPaiDan
                  }}</span>
                </div>
                <div class="flex" style="width: 25%; font-size: 0.9rem">
                  已归档:<span class="complete">{{
                    item.GaiKuang.YiGuiDang
                  }}</span>
                </div>
              </div>
            </div>
            <div class="godetail">
              <el-button
                type="primary"
                size="mini"
                round
                @click="godetail(index, item)"
                >详细信息
                <i
                  class="el-icon--right"
                  :class="
                    item.isShow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
                  "
                ></i
              ></el-button>
              <el-button round size="mini" @click="downloadReport(item)"
                >报告下载</el-button
              >
            </div>
          </div>
          <div class="content dark" ref="isshow">
            <!-- 处理告警条数 -->
            <div class="tips dark">
              <img src="../../assets/images/equment/叹号.png" alt="" />
              <div>
                {{ item.Message }}
              </div>
            </div>
            <!-- 告警分类 -->
            <div class="alarmClass">
              <img src="../../assets/images/equment/分类2.png" alt="" />
              <div>告警分类</div>
            </div>
            <!-- 告警分类详情 -->
            <div class="alarmClassicon">
              <div
                v-for="(value, index) in item.GuZhangLeiXing"
                :key="index"
                class="adetails"
              >
                <div class="radius-blue">
                  <icon1 :icon-class="value.Icon" class="swsw" style="color:#25c1ff"></icon1>
                </div>
                <div>{{ value.Text }}</div>
                <div class="aend">{{ value.Value }}项</div>
              </div>
            </div>
            <!-- 告警设备 -->
            <div
              class="alarmEquip"
              v-if="item.SheBeiLeiXing.length == 0 ? false : true"
            >
              <img src="../../assets/images/equment/设备.png" alt="" />
              <div>告警设备</div>
            </div>
            <!-- 告警设备详情 -->
            <div
              class="alarmEquipcion"
              v-if="item.SheBeiLeiXing.length == 0 ? false : true"
            >
              <div
                v-for="(value, index) in item.SheBeiLeiXing"
                :key="index"
                class="adetails"
              >
                <div class="radius-blue">
                  <icon1 :icon-class="value.Icon" class="swsw"></icon1>
                </div>
                <div>{{ value.Text }}</div>
                <div class="aend">{{ value.Value }}项</div>
              </div>
            </div>
            <!-- 检测项目明细 -->
            <div class="details">
              <i class="icon-checked el-icon-success"></i>
              <div>检测项目明细：</div>
            </div>
            <!-- 检测项目明细详情 -->
            <div class="detailsicon">
              <div
                class="zong"
                v-for="(value, index1) in item.JianCeXiangMu"
                :key="index1"
              >
                <div class="hui-border">
                  <div class="a">
                    <div class="a1a">
                      <img src="../../assets/images/equment/明细.png" alt="" />
                      {{ value.JKD_ClassName }}--共<span class="a1span1">{{
                        value.JKD_WeightedScore
                      }}</span
                      >分
                      <span class="a1span2"
                        >（实际得分{{ value.JKD_Score }}占比{{
                          value.JKD_Weight
                        }}%变压器健康度）</span
                      >
                    </div>
                    <div class="a22" @click="show(index1, index)">
                      <img
                        src="../../assets/images/equment/指下.png"
                        alt=""
                        :ref="'p' + index1 + index"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="hui-border"
                  style="display: flex; justify-content: center"
                >
                  <div class="aa" :ref="'a' + index1 + index">
                    <div class="aa1" v-for="(v, i) in value.Children" :key="i">
                      <div class="radius-blue">
                        <icon1 :icon-class="v.Icon" class="swsw"></icon1>
                      </div>
                      <div>{{ v.Text }}</div>
                      <div class="aend">{{ v.Value }}项</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <no-data-temporarily class="flex-grow-1" v-else></no-data-temporarily>
    </SectionFrame>
  </div>
</template>
<script>
import ProjectMixin from '@/mixins/project-mixin';
import {
  getEvaluation,
  getOperationOverviewExcel,
  runEvaluation,
} from '@/api/equipment-condition-evaluation/evaluation/evaluation';
// import RoomSelector from '@/components/utils/room-selector.vue';
import NoDataTemporarily from '@/components/utils/no-data-temporarily.vue';
import icon1 from '@/components/utils/icon.vue';
import downIcon from '@/assets/images/equment/指下.png';
import upIcon from '@/assets/images/equment/指上.png';

export default {
  name: 'project-condition-evaluation',
  mixins: [ProjectMixin],
  data() {
    return {
      downIcon,
      upIcon,
      listD: [],
      details: [],
      selectRoom: null,
      fen: [],
      flag: true,
      wan: false,
      state: true,
      heatInfo: null,
    };
  },
  watch: {
    fen(newVal) {
      this.bianse(newVal);
    },
    deep: true,
  },
  components: {
    // RoomSelector,
    icon1,
    NoDataTemporarily,
  },
  created() {
    this.requestDataList();
  },
  computed: {
    accountId() {
      return this.$store.getters.accountId;
    },
    selectProject() {
      return this.$store.getters.selectProject;
    },
  },
  methods: {
    exportToConditon() {
      this.$router.push({
        name: 'project-equipments-confition',
      });
    },
    downloadReport(item) {
      getOperationOverviewExcel(item.TabIDstr).then(() => {
        this.$message({
          message: '下载成功',
          type: 'success',
        });
      });
    },
    // 进行详情信息的折叠展开
    godetail(index, item) {
      // eslint-disable-next-line no-param-reassign
      this.$set(item, 'isShow', !item.isShow);
      if (
        this.$refs.isshow[index].style.display === ''
        || this.$refs.isshow[index].style.display === 'none'
      ) {
        this.$refs.isshow[index].style.display = 'flex';
        this.$refs.situat[index].style.height = `${100}%`;
        this.$refs.situat1[index].style.height = `${19}%`;
      } else {
        this.$refs.isshow[index].style.display = 'none';
        this.$refs.situat[index].style.height = `${21}%`;
        this.$refs.situat1[index].style.height = `${90}%`;
      }
    },
    // 请求接口 传项目ID
    requestDataList() {
      if (this.selectProject === null) {
        return;
      }
      getEvaluation({
        ProjectIDstr: this.selectProject.IdStr,
      })
        .then((res) => {
          this.details = [];
          this.fen = [];
          this.listD = res.List;
          Promise.all(
            res.List.map((item) => runEvaluation({
              TaskIDstr: item.TabIDstr,
              UIDstr: this.accountId,
            })),
          )
            .then((resList) => {
              this.details = resList.map(
                (resItem, index) => Object.assign(resItem,
                  { TabIDstr: this.listD[index].TabIDstr }),
              );
              this.fen = resList.map((resInfo) => resInfo.Score);
            })
            .catch(() => {
              this.details = [];
              this.fen = [];
            });
        })
        .catch(() => {
          this.listD = [];
          this.details = [];
          this.fen = [];
        });
    },
    // 接受选择配电室的值
    // selectItemListener(selectNode) {
    //   if (selectNode.oType !== 'pds') {
    //     return;
    //   }
    //   this.selectRoom = selectNode;
    //   getEvaluation({
    //     PDSIDstr: selectNode.IdStr,
    //   })
    //     .then((res) => {
    //       this.details = [];
    //       this.fen = [];
    //       this.listD = res.List;
    //       Promise.all(
    //         res.List.map((item) => runEvaluation({
    //           TaskIDstr: item.TabIDstr,
    //           UIDstr: this.accountId,
    //         })),
    //       )
    //         .then((resList) => {
    //           this.details = resList.map(
    //             (resItem, index) => Object.assign(resItem,
    //               { TabIDstr: this.listD[index].TabIDstr }),
    //           );
    //           this.fen = resList.map((resInfo) => resInfo.Score);
    //         })
    //         .catch(() => {
    //           this.details = [];
    //           this.fen = [];
    //         });
    //     })
    //     .catch(() => {
    //       this.listD = [];
    //       this.details = [];
    //       this.fen = [];
    //     });
    // },

    // 检测项目明细详情随箭头随展开收缩
    show(i, b) {
      const a = `a${i}${b}`;
      const p = `p${i}${b}`;
      if (
        this.$refs[a][0].style.display === 'none'
        || this.$refs[a][0].style.display === ''
      ) {
        this.$refs[a][0].parentNode.style.borderBottom = '#3344aa solid 1px';
        this.$refs[a][0].style.display = 'flex';
        this.$refs[p][0].src = upIcon;
      } else {
        this.$refs[a][0].parentNode.style.borderBottom = '';

        this.$refs[a][0].style.display = 'none';

        this.$refs[p][0].src = downIcon;
      }
    },
    // 根据分数的数组进行文字颜色变化
    bianse(a) {
      if (!this.$refs.bianse) {
        return;
      }
      for (let i = 0; i < this.$refs.bianse.length; i += 1) {
        if (a[i] >= 80) {
          this.$refs.bianse[i].style.color = 'green';
        }
        if (a[i] >= 60 && a[i] < 80) {
          this.$refs.bianse[i].style.color = 'pink';
        }
        if (a[i] < 60) {
          this.$refs.bianse.style.color = 'red';
        }
      }
    },
  },
};
</script>
<style lang='less' scoped>
.icon {
  width: 0.9em;
  height: 1.3em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.hui-border {
  width: 76%;
}
.dark .radius-blue {
  // background-color: #2e9bf3;
  //  background-color: #1C4394;
  background-color:#1b3f87;
}
.radius-blue {
  background: rgb(102, 177, 255);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swsw {
  width: 2rem;
  height: 2rem;
}
.content {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  height: 79%;
}
.content::-webkit-scrollbar {
  width: 0px;
}

.content::-o-scrollbar {
  width: 0px;
}
.content::-moz-scrollbar {
  width: 0px;
}
.ce1 {
  width: 100%;
  height: 100rem;
  background: cornflowerblue;
}
.flex {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.blue {
  color: #0385d7;
}
.titleState {
  padding: 1rem;
  margin-bottom: 0.6rem;
  display: flex;
  align-items: center;
  position: relative;
}
.section-header {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
.title-line {
  width: 0.3rem;
  height: 1.7rem;
  background: #2e9bf3;
}
.tip4text {
  font-size: 1.2rem;
  margin-left: 1rem;
}
.query-room {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.conState {
  height: 94%;
  overflow-y: auto;
  width: 100%;
}
.situation {
  width: 100%;
  height: 21%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.situation1 {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  position: relative;
}
.qui {
  width: 7rem;
  height: 7rem;
  position: absolute;
  left: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grade {
  width: 100%;
  height: 100%;
}
.shu {
  position: absolute;
  font-size: 2.5rem;
}
.fen {
  font-size: 1rem;
}
.dectext {
  position: absolute;
  left: 20%;
  width: 63%;
  height: 72%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.nameAsolution {
  width: 100%;
  height: 28%;
  display: flex;
  align-items: center;
}
.cont1,
.cont2,
.cont3 {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
}
.godetail {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 80%;
}
.detail-la {
  width: 34%;
  height: 18%;
  border-radius: 2rem;
  justify-content: center;
  border: #0385d7 solid 0.1rem;
  align-items: center;
  font-size: 0.9rem;
  cursor: pointer;
}
.bianxiao {
  width: 1.5rem;
  height: 1.1rem;
  margin-left: 5%;
}
.allEvaluation {
  width: 100%;
  height: 16.75rem;
  margin-top: 1.2857rem;
  background-color: #ecf3f9;
  display: flex;
  flex-direction: row;
  /* align-content: flex-start; */
  align-items: center;
}
.tips,
.alarmClass,
.alarmEquip,
.details {
  display: flex;
  align-items: center;
  width: 78%;
  height: 3rem;
  min-height: 3rem;
  margin-left: -2%;
  border-bottom: #ececec solid 0.1rem;
}
.a {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  border-bottom: rgb(228, 226, 226) solid 1px;
  font-size: 0.9rem;
}
.dark .tips,
.dark .alarmClass,
.dark .alarmEquip,
.dark .details,
.dark .a {
  border-bottom: solid 1px #3344aa;
}
.aa {
  display: none;
  margin-top: 1%;
  width: 97%;
  height: 6.71428rem;
  min-height: 6.71428rem;
}
.alarmClassicon,
.alarmEquipcion {
  display: flex;
  margin-top: 1%;
  width: 80%;
  height: 6.71428rem;
  margin-left: 4%;
  min-height: 6.71428rem;
}
.operatView {
  width: 50%;
  height: 80%;

  margin-top: 1%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.notes {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: flex-start;
}
.notes1 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333;
  font-family: MicrosoftYaHei;
}
.notes2 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  align-items: center;
  color: #666666;
}
.major {
  display: flex;
  flex-grow: 1;
  width: 45rem;

  flex-wrap: wrap;
  justify-content: flex-start;
}
.major1 {
  margin-right: 1%;
  width: 20%;
  height: 50%;
  justify-content: flex-start;
  display: flex;
  font-size: 1.1428rem;
  flex-wrap: nowrap;
}
.major1 > span {
  color: rgb(0, 162, 255);
}
.repaire {
  width: 7.6428rem;
  height: 2.7857rem;
  border-radius: 10rem;
  border: #0385d7 solid 0.1rem;
  margin-left: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0385d7;
  font-size: 1.42857rem;
  font-family: MicrosoftYaHei;
}
.tips > img {
  width: 1.1rem;
  height: 1.1rem;
  margin-left: 4rem;
}
.dark .tips > div {
  color: #fff;
}

.tips > div {
  font-size: 0.9rem;
  margin-left: 0.7%;
}

.tips.dark > div {
  color: #fff;
}
.tips > div > span {
  color: red;
}
.alarmClass > img,
.alarmEquip > img,
.details > img {
  width: 1.2142rem;
  margin-left: 3%;
}
.alarmClass > div,
.alarmEquip > div,
.details > div {
  font-size: 0.9rem;
  margin-left: 0.7%;
}

.adetails,
.aa1 {
  width: 9%;
  height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1%;
  font-size: 0.5rem;
}
.adetails > img,
.aa1 > img {
  width: 4.42857rem;
}
.imgIcon {
  width: 5rem;
  height: 5rem;
}
.adetails > div,
.aa1 > div {
  margin-top: 2%;
}
.aend {
  color: rgb(255, 255, 255);
}
.dark .aend {
  // background-color: #2e9bf3;
  background-color: #25C1FF;
}
.icon-checked {
  color: #2e9bf3;
}
.aend {
  background-color: rgb(102, 177, 255);
  border-radius: 10rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2%;
}
.zong {
  font-family: MicrosoftYaHei;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detailsicon {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.a1a {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.a1a > img {
  width: 1.428rem;
  height: 1.428rem;
  margin-right: 0.5rem;
}
.a1span1 {
  color: rgb(0, 153, 255);
}
.a1span2 {
  margin-left: 5%;
}
.a22 {
  width: 2.5%;
  height: 100%;

  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 5rem;
}
.a22 > img {
  width: 40%;
  height: 20%;
}

.aa1 > img {
  width: 40%;
}
.aa1end {
  background-color: rgb(0, 162, 255);
  border-radius: 10rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2%;
}
.danger {
  color: #f56c6c;
  font: 1.05rem;
  padding:0 2%;
}
.dark .info {
  // text-indent: 5px;
  font-size:1.05rem;
  padding:0 2%;
  color: #25C1FF;
}
.success {
  color: #67c23a;
  font: 1.05rem;
  padding:0 2%;
}
.complete {
  font: 1.05rem;
  padding:0 2%;
}
.content.dark {
  // background-color: #223388;
  // background-color: #223388;
  background: linear-gradient(15deg, #1c4596, #181d62);
}
/deep/.el-progress.dark .el-progress__text{
  color: #25C1FF;
  font-size: 1.4rem!important;
}
.el-button.is-round{
  border-radius: 2px;
  background-color: #1C4394;
}
.dark .el-button.el-button--\=, .dark .el-button.el-button--default{
  border:1px solid hsl(197, 100%, 57%);
  background: #1C4394;
}
.section-living{
  // background-color: #151A59;
  width: 99%;
  border: 1px solid #1b71cc;
//   background: #1C4394;
  background: linear-gradient(15deg, #1c4596, #181d62);
}
</style>
