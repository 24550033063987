import { request } from '../../../utils/request/request';

// export function getEvaluation(query) {
//   return request({
//     url: 'api/tongji/v1/OperationOverview_List',
//     method: 'get',
//     params: query,
//   });
// }
export function getEvaluation(query) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_OperationOverview_List',
    method: 'get',
    params: query,
  });
}
export function selectRoom(query) {
  return request({
    url: 'api/equipment/v1/EquipmentTopology',
    method: 'get',
    params: query,
  });
}
export function getOperationOverviewExcel(id) {
  return request({
    url: 'api/tongji/v1/OperationOverview_Info_ToExcel',
    method: 'GET',
    params: {
      StationIDstr: id,
    },
  });
}
export function runSituation(query) {
  return request({
    url: 'api/tongji/v1/OperationOverview_Info',
    method: 'get',
    params: query,
  });
}

export function runEvaluation(query) {
  return request({
    url: 'api/APPAPI_NEW/v1/JPTQ_OperationOverview_Info',
    method: 'get',
    params: query,
  });
}
