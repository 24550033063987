<template>
  <span class="iconfont" :class="iconClass" style="color:#25c1ff;"></span>
</template>
<script>
export default {
  name: 'icon',
  props: {
    iconClass: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconName() {
      return `#${this.iconClass}`;
    },
  },
};
</script>
<style scoped>
.iconStyle{
    width: 2em;
    height: 2em;
    vertical-align: -0.15em;
    /* fill: #bfcbd9; */
    overflow: hidden;
}

</style>
